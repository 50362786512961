import React, { useEffect, useRef } from 'react'
import { ProfilType } from '../Profil.type'
import Autocomplete from 'react-google-autocomplete'

interface FormDataProps {
  formData: {
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    typeBien: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    selectedProfiles: ProfilType[]
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      date: Date
      heureVisite: string
      proprietaire: string
      adresseBien: string
      ville: string
      prix: { email: string; prix: number }[]
      informationsComplementaires: string
      codePostal: string
      typeBien: string
      selectedProfiles: ProfilType[]
    }>
  >
  formDataValid: any
  setFormDataValid: React.Dispatch<React.SetStateAction<any>>
}

export default function Part2({
  formData,
  setFormData,
  formDataValid,
  setFormDataValid,
}: FormDataProps) {
  const villeRef = useRef(null) as any
  const adressDuBienRef = useRef(null) as any

  // Champ "Propriétaire"
  const handleProprietaireChange = (e: any) => {
    const proprietaire = e.target.value

    setFormDataValid((pev: any) => ({
      ...pev,
      proprietaire: true,
    }))

    setFormData({ ...formData, proprietaire })
  }

  // Champ "Code postal"
  const handleCodePostalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const codePostal = e.target.value

    setFormDataValid((pev: any) => ({
      ...pev,
      codePostal: true,
    }))

    setFormData({ ...formData, codePostal })
  }

  // Champ "Type de bien"
  const handleTypeBienChange = (e: { target: { value: any } }) => {
    const typeBien = e.target.value

    setFormDataValid((pev: any) => ({
      ...pev,
      typeBien: true,
    }))

    setFormData({ ...formData, typeBien })
  }

  useEffect(() => {
    if (formData.adresseBien && formData.ville) {
      adressDuBienRef.current.value = formData.adresseBien
      villeRef.current.value = formData.ville
    }
  }, [])

  return (
    <div>
      <h2 className='programmation-visite-sous-titre '>Planifier votre visite</h2>
      <div className='part-content mt-2 '>
        <div>
          <label className='block text-sm font-medium text-gray-600'>Propriétaire :</label>
          <select
            data-testid="select-proprietaire"
            value={formData.proprietaire}
            onChange={handleProprietaireChange}
            className='mt-1 p-2 border rounded-md w-full'
          >
            <option value='' disabled hidden>
              Sélectionnez le type
            </option>
            <option value='particulier'>Particulier</option>
            <option value='agence'>Agence</option>
          </select>
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-600'>Adresse du bien :</label>
          <div className='mt-1 p-2 border rounded-md w-full'>
            <Autocomplete
                data-testid="adresse-proprietaire"
              ref={adressDuBienRef}
              apiKey={process.env.REACT_APP_GOOGLE}
              onPlaceSelected={(place) => {
                const codePostalObj = place.address_components.find(
                  (a: any) => a.types[0] === 'postal_code',
                )
                let codePostal = ''
                if (codePostalObj) codePostal = codePostalObj.short_name

                const ville = adressDuBienRef.current.value.split(',')[1]
                villeRef.current.value = ville

                setFormDataValid((pev: any) => ({
                  ...pev,
                  adresseBien: true,
                  codePostal: codePostal ? true : false,
                  ville: true,
                }))
                setFormData((prevData) => ({
                  ...prevData,
                  adresseBien: place.formatted_address.split(',')[0],
                  codePostal,
                  ville,
                }))

                adressDuBienRef.current.value = adressDuBienRef.current.value.split(',')[0]
              }}
              options={{
                types: ['route'],
                componentRestrictions: { country: 'fr' },
              }}
            />
          </div>
        </div>
      </div>
      <div className='part-content '>
        <div>
          <label className='block text-sm font-medium text-gray-600'>Ville :</label>

          <div className='mt-1 p-2 border rounded-md w-full'>
            <Autocomplete
                data-testid="ville-proprietaire"
              ref={villeRef}
              apiKey={process.env.REACT_APP_GOOGLE}
              onPlaceSelected={(place) => {
                setFormDataValid((pev: any) => ({
                  ...pev,
                  ville: true,
                }))
                setFormData((prevData) => ({
                  ...prevData,
                  ville: place.address_components[0].short_name,
                }))
              }}
              options={{
                componentRestrictions: { country: 'fr' },
              }}
            />
          </div>
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-600'>Code postal :</label>
          <input
            type='text'
            data-testid="code_postal-proprietaire"
            onChange={handleCodePostalChange}
            placeholder='Code postal'
            className='mt-1 p-2 border rounded-md w-full'
            value={formData.codePostal}
          />
        </div>
      </div>
      <div className='part-content'>
        <div>
          <label className='block text-sm font-medium text-gray-600'>Type de bien :</label>
          <select
              data-testid="select-type_bien"
            value={formData.typeBien}
            onChange={handleTypeBienChange}
            className='mt-1 p-2 border rounded-md w-full'
          >
            <option value='' disabled hidden>
              Sélectionnez le type
            </option>
            <option value='appartement'>Appartement</option>
            <option value='maison'>Maison</option>
          </select>
        </div>
      </div>
    </div>
  )
}
