/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import withAuth from '../Security/withAuth'
import AddIcon from '@mui/icons-material/Add'
import etourneau from '../../assets/images/image_oiseaux/etourneau.png'
import { updateVisite } from '../../api/Visites'
import swal from 'sweetalert'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { createNotifications } from '../../api/Notifications'

type PartType = 'generalInformations' | 'informationsPieces' | 'otherSpaces' | 'environnement'

const compteRenduVisiteur: React.FC<any> = ({
  dataVisite,
  authState,
  token,
  chat,
  setOpenCompteRendu,
}) => {
  const [selectedFile, setSelectedFile] = useState(null) as any
  const [compteRendu, setCompteRendu] = useState({
    generalInformations: {
      adresse: dataVisite.adresseBien,
      type: dataVisite.typeBien,
      isMeublé: false,
      nombreAppart: '',
      nombreEtageBatiment: '',
      nombreEtage: '',
      ascenseur: {
        isPresent: dataVisite.typeBien === 'appartement' ? true : false,
        detail: '',
      } as {
        isPresent: boolean
        detail: string
      },
      superficie: 0,
      nombrePiece: 0,
      chauffage: { type: '', detail: '' },
      chauffeEau: { type: '', detail: '' },
      prisesMural: {
        auNormes: true,
        detail: '',
      } as any,
      orientationSejour: '',
      isolationThermiquePhonique: '',
    },
    informationsPieces: {
      detailSalon: '',
      cuisine: {
        ouverte: false,
        vmc: false,
        detail: '',
      } as any,
      salleDeBain: {
        type: '',
        vmc: false,
        vasque: '',
      } as any,
      toilette: {
        séparé: false,
        detail: '',
      } as any,
      chambre: {
        parental: false,
        detail: '',
      } as any,
    },
    otherSpaces: {
      haveJardin: false,
      jardinSuperficie: '',
      visAvis: false,
      cloture: false,
      arboré: false,
      otherDetail: '',
      cave: false,
      superficieCave: '',
      detailCave: '',
      parking: false,
      parkingHaveNum: false,
      parkingIsSecur: false,
      parkingDetail: '',
    },
    environnement: {
      trafic: '',
      nuissances: [] as string[],
      easyStationnement: false,
      nearTransport: false,
      commerces: false,
      reseauDetail: '',
      visAvis: false,
      otherDetail: '',
      demandesSpecifique: '',
    },
    fichiers: [] as any,
  })

  const submitData = async ({ isSubmit, haveData }: any) => {
    let dataToSend: any = { ...compteRendu }
    let text = 'Compte rendu modifié avec succès :)'

    if (haveData) {
      dataToSend = { ...dataToSend, haveData }
      text = 'Compte rendu enregistré avec succès :)'
    } else if (isSubmit) {
      dataToSend = { ...dataToSend, isSubmit, haveData: true }
      text = 'Compte rendu envoyé avec succès :)'
      const data = {
        type: 'CompteRenduSend',
        content: 'Compte rendu envoyé',
        userImg: authState.userImg,
        firstName: authState.firstname,
        lastName: authState.lastname,
        chatId: chat.id,
      }
      if (dataVisite.clientEmail)
        await createNotifications(token, authState.firstname, [dataVisite.clientEmail], data)
    }

    const formData = new FormData()

    compteRendu.fichiers.forEach((fichier: any) => {
      formData.append('fichiers', fichier.file) // Utilisez un nom de champ simple
    })
    formData.append('compte_rendu', JSON.stringify(dataToSend))

    const isUpdate = await updateVisite(token, dataVisite.id, formData)

    if (isUpdate.success) {
      swal({
        title: 'Good job!',
        text,
        icon: 'success',
      }).then((value) => {
        if (value) {
          setOpenCompteRendu(false)
        }
      })
    }
  }

  const handleChangeValue = (newValue: any, key: string, part: string) => {
    setCompteRendu((value) => ({
      ...value,
      [part]: {
        ...value[part as PartType],
        [key]: newValue,
      },
    }))
  }

  const handleFileChange = async (e: any) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const newFiles = Array.from(files).map((file: any) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file), // Crée une URL locale pour afficher l'image
        file: file, // Conserve une référence au fichier réel pour l'envoi ultérieur
      }))

      setCompteRendu((value) => ({
        ...value,
        fichiers: [...value.fichiers, ...newFiles],
      }))
    }
  }

  useEffect(() => {
    if (dataVisite.compte_rendu.haveData || dataVisite.compte_rendu.isSubmit)
      setCompteRendu(dataVisite.compte_rendu)
  }, [])

  return (
    <>
      {/* FULL SCREEN IMG  */}
      {selectedFile && (
        <div className='fixed top-0 z-50 left-0 bg-gray-200 bg-opacity-70 w-full h-full'>
          <div className='relative top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center'>
            <button
              className=' absolute top-5 right-5 bg-white  border border-gray-400 rounded-full p-1 hover:bg-gray-50 hover:scale-105 transition-transform delay-100'
              onClick={() => {
                setSelectedFile(null)
              }}
            >
              <CloseIcon fontSize='small' />
            </button>
            <img className=' px-4 max-h-[80%]' src={selectedFile.url} />
            <div className='flex gap-3'>
              <button>
                {' '}
                <ArrowBackIosIcon
                  onClick={() => {
                    const previousIndex = selectedFile.index - 1

                    const previousFile = compteRendu.fichiers.find(
                      (f: any, i: any) => i === previousIndex,
                    )
                    if (previousFile) setSelectedFile({ ...previousFile, index: previousIndex })
                  }}
                  fontSize='small'
                />{' '}
              </button>
              <button
                onClick={() => {
                  const nextIndex = selectedFile.index + 1
                  const nextFile = compteRendu.fichiers.find((f: any, i: any) => i === nextIndex)
                  if (nextFile) setSelectedFile({ ...nextFile, index: nextIndex })
                }}
              >
                {' '}
                <ArrowForwardIosIcon fontSize='small' />{' '}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='flex justify-between '>
        <h2 className='mb-6'>Compte rendu</h2>
        <div className='flex justify-center items-start'>
          <button
            className='border border-gray-400 rounded-full p-1 hover:bg-gray-50 hover:scale-105 transition-transform delay-100'
            onClick={() => {
              setOpenCompteRendu(false)
            }}
          >
            <CloseIcon fontSize='small' />
          </button>
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row gap-2 md:justify-between w-full '>
        {/* left */}
        <div className='flex flex-col gap-2 overflow-y-auto md:max-h-[78vh] max-h-[58vh]  md:w-[69.5%] w-full scrollbar-review  '>
          {/* GENERALINFOS */}
          <h3 className='underline mb-3 '>Informations générales sur le logement</h3>
          <input
            value={compteRendu.generalInformations.adresse}
            type='text'
            disabled={!authState.isVisiteur}
            name='adresse'
            id='adresse'
            onChange={(e) => handleChangeValue(e.target.value, 'adresse', 'generalInformations')}
            placeholder='Adresse'
            className='border border-gray-300 p-1 max-w-[60%]'
          />
          <div className='flex justify-between items-center max-w-[350px]'>
            <div>
              <label htmlFor='toggleMaison'>Maison</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                type='checkbox'
                id='toggleMaison'
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                checked={compteRendu.generalInformations.type === 'maison'}
                onChange={() => handleChangeValue('maison', 'type', 'generalInformations')}
              />
            </div>
            <div>
              <label htmlFor='toggleAppartement'>Appartement</label>{' '}
              <input
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                disabled={!authState.isVisiteur}
                id='toggleAppartement'
                checked={compteRendu.generalInformations.type === 'appartement'}
                onChange={() => handleChangeValue('appartement', 'type', 'generalInformations')}
              />
            </div>
            <div>
              <label htmlFor='toggleMeuble'>Meublé</label>{' '}
              <input
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                disabled={!authState.isVisiteur}
                id='toggleMeuble'
                checked={compteRendu.generalInformations.isMeublé === true}
                onChange={(e) =>
                  handleChangeValue(e.target.checked, 'isMeublé', 'generalInformations')
                }
              />
            </div>
          </div>
          {compteRendu.generalInformations.type === 'appartement' && (
            <>
              <div className='flex gap-1'>
                <label htmlFor='numberApp'>Nombre d'appartement dans le bâtiment</label>{' '}
                <input
                  disabled={!authState.isVisiteur}
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 'nombreAppart', 'generalInformations')
                  }
                  className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
                  type='number'
                  id=''
                  maxLength={2}
                  value={compteRendu.generalInformations.nombreAppart}
                  min={0}
                />
              </div>
              <div className='flex gap-1'>
                <label htmlFor='numberApp'>Nombre d'étages dans le bâtiment</label>{' '}
                <input
                  disabled={!authState.isVisiteur}
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 'nombreEtageBatiment', 'generalInformations')
                  }
                  className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
                  type='number'
                  id=''
                  maxLength={2}
                  value={compteRendu.generalInformations.nombreEtageBatiment}
                  min={0}
                />
              </div>
            </>
          )}
          <div className='flex gap-1'>
            <label htmlFor='numberApp'>Nombre d'étages du logement visité</label>{' '}
            <input
              onChange={(e) =>
                handleChangeValue(e.target.value, 'nombreEtage', 'generalInformations')
              }
              disabled={!authState.isVisiteur}
              className=' border-b border-gray-300  min-w-[32px]  max-w-[45px] text-center'
              type='number'
              id=''
              maxLength={2}
              min={0}
              value={compteRendu.generalInformations.nombreEtage}
            />
          </div>
          <div className='flex gap-1'>
            <span>Ascenseur:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.ascenseur.isPresent === true}
                onChange={(e) => {
                  if (e.target.checked)
                    setCompteRendu((value) => ({
                      ...value,
                      generalInformations: {
                        ...value.generalInformations,
                        ascenseur: {
                          ...value.generalInformations.ascenseur,
                          isPresent: true,
                        },
                      },
                    }))
                }}
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.ascenseur.isPresent === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      ascenseur: {
                        ...value.generalInformations.ascenseur,
                        isPresent: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          {compteRendu.generalInformations.ascenseur.isPresent && (
            <div className='max-h-[200px]'>
              <textarea
                disabled={!authState.isVisiteur}
                onChange={(e) => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      ascenseur: {
                        ...value.generalInformations.ascenseur,
                        detail: e.target.value,
                      },
                    },
                  }))
                }}
                value={compteRendu.generalInformations.ascenseur.detail}
                name='infosAscenseur'
                id='infosAscenseur'
                placeholder='Praticité, état...'
                className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
              />
            </div>
          )}
          <div className='flex gap-1'>
            <label htmlFor='superficie'>Superficie générale</label>{' '}
            <div>
              <input
                onChange={(e) =>
                  handleChangeValue(e.target.value, 'superficie', 'generalInformations')
                }
                className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
                type='number'
                id=''
                min={0}
                disabled={!authState.isVisiteur}
                maxLength={2}
                value={compteRendu.generalInformations.superficie}
              />
              m2
            </div>
          </div>
          <div className='flex gap-1'>
            <label htmlFor='Nombre de pièces'>Nombre de pièces</label>{' '}
            <input
              onChange={(e) =>
                handleChangeValue(e.target.value, 'nombrePiece', 'generalInformations')
              }
              className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
              type='number'
              id=''
              disabled={!authState.isVisiteur}
              maxLength={2}
              min={0}
              value={compteRendu.generalInformations.nombrePiece}
            />
          </div>
          <div className='flex gap-1'>
            <span>Chauffage:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Gaz</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffage.type === 'gaz'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffage: {
                        ...value.generalInformations.chauffage,
                        type: 'gaz',
                      },
                    },
                  }))
                }}
                value={compteRendu.generalInformations.chauffage.detail}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Electrique</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffage.type === 'electrique'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffage: {
                        ...value.generalInformations.chauffage,
                        type: 'electrique',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Autre</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffage.type === 'autre'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffage: {
                        ...value.generalInformations.chauffage,
                        type: 'autre',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]  '>
            <textarea
              disabled={!authState.isVisiteur}
              name='Chauffe-eau'
              id='Chauffe-eau'
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  generalInformations: {
                    ...value.generalInformations,
                    chauffage: {
                      ...value.generalInformations.chauffage,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              value={compteRendu.generalInformations.chauffage.detail}
              placeholder='Type,état,précisions...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <div className='flex gap-1'>
            <span>Chauffe-eau:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Gaz</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffeEau.type === 'gaz'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffeEau: {
                        ...value.generalInformations.chauffeEau,
                        type: 'gaz',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Electrique</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffeEau.type === 'electrique'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffeEau: {
                        ...value.generalInformations.chauffeEau,
                        type: 'electrique',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Autre</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.chauffeEau.type === 'autre'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      chauffeEau: {
                        ...value.generalInformations.chauffeEau,
                        type: 'autre',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  generalInformations: {
                    ...value.generalInformations,
                    chauffeEau: {
                      ...value.generalInformations.chauffeEau,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              value={compteRendu.generalInformations.chauffeEau.detail}
              name='Chauffe-eau'
              id='Chauffe-eau'
              placeholder='Capacité,état...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <div className='flex gap-1'>
            <span>Prises murales aux normes:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.prisesMural.auNormes === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      prisesMural: {
                        ...value.generalInformations.prisesMural,
                        auNormes: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.generalInformations.prisesMural.auNormes === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    generalInformations: {
                      ...value.generalInformations,
                      prisesMural: {
                        ...value.generalInformations.prisesMural,
                        auNormes: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className=' max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              name='Chauffe-eau'
              id='Chauffe-eau'
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  generalInformations: {
                    ...value.generalInformations,
                    prisesMural: {
                      ...value.generalInformations.prisesMural,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              value={compteRendu.generalInformations.prisesMural.detail}
              placeholder='Etat compteur,fils plafonds,système électrique...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <div className='flex gap-1'>
            <label htmlFor='numberApp'>Orientation du sejour:</label>{' '}
            <input
              disabled={!authState.isVisiteur}
              onChange={(e) =>
                handleChangeValue(e.target.value, 'orientationSejour', 'generalInformations')
              }
              className=' border-b border-gray-300 w-8'
              type='text'
              id=''
              value={compteRendu.generalInformations.orientationSejour}
            />
          </div>
          <label htmlFor='numberApp'>Isolation phonique et thermique</label>{' '}
          <div className=' max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) =>
                handleChangeValue(
                  e.target.value,
                  'isolationThermiquePhonique',
                  'generalInformations',
                )
              }
              value={compteRendu.generalInformations.isolationThermiquePhonique}
              name='Isolation'
              id='Isolation'
              placeholder='Type,état...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          {/* PIECES INFORMATIONS  */}
          <h3 className='underline my-3'>Informations sur chaque pièce</h3>
          <label htmlFor='Salon ' className='font-semibold'>
            Salon:
          </label>{' '}
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) =>
                handleChangeValue(e.target.value, 'detailSalon', 'informationsPieces')
              }
              name='Salon'
              id='Salon'
              value={compteRendu.informationsPieces.detailSalon}
              placeholder='Caractéristiques (supérficie,état,rangements...)'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <span className='font-semibold'>Cuisine:</span>
          <div className='flex gap-1'>
            <span>Ouverte:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.cuisine?.ouverte === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      cuisine: {
                        ...value.informationsPieces.cuisine,
                        ouverte: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.cuisine?.ouverte === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      cuisine: {
                        ...value.informationsPieces.cuisine,
                        ouverte: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>VMC:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.cuisine?.vmc === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      cuisine: {
                        ...value.informationsPieces.cuisine,
                        vmc: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.cuisine?.vmc === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      cuisine: {
                        ...value.informationsPieces.cuisine,
                        vmc: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  informationsPieces: {
                    ...value.informationsPieces,
                    cuisine: {
                      ...value.informationsPieces.cuisine,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              name='infosCuisine'
              id='infosCuisine'
              value={compteRendu.informationsPieces.cuisine.detail}
              placeholder='Caractéristiques (équipement,rangements...)'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <div className='flex gap-1'>
            <span className='font-semibold'>Salle de brain:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Douche </label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.type === 'douche'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        type: 'douche',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Baignoire</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.type === 'baignoire'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        type: 'baignoire',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Autre</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.type === 'autre'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        type: 'autre',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>VMC:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.vmc === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        vmc: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.vmc === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        vmc: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Vasque:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Simple</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.vasque === 'simple'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        vasque: 'simple',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Double</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.salleDeBain.vasque === 'double'}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      salleDeBain: {
                        ...value.informationsPieces.salleDeBain,
                        vasque: 'double',
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <span className='font-semibold'>Toilettes:</span>
          <div className='flex gap-1'>
            <span>Séparées:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.toilette.séparé === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      toilette: {
                        ...value.informationsPieces.toilette,
                        séparé: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.toilette.séparé === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      toilette: {
                        ...value.informationsPieces.toilette,
                        séparé: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  informationsPieces: {
                    ...value.informationsPieces,
                    toilette: {
                      ...value.informationsPieces.toilette,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              value={compteRendu.informationsPieces.toilette.detail}
              name='Toilettes'
              id='Toilettes'
              placeholder='Caractéristiques,état...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <span className='font-semibold'>Chambre:</span>
          <div className='flex gap-1'>
            <span>Suite parentale:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.chambre.parental === true}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      chambre: {
                        ...value.informationsPieces.chambre,
                        parental: true,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.informationsPieces.chambre.parental === false}
                onChange={() => {
                  setCompteRendu((value) => ({
                    ...value,
                    informationsPieces: {
                      ...value.informationsPieces,
                      chambre: {
                        ...value.informationsPieces.chambre,
                        parental: false,
                      },
                    },
                  }))
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              onChange={(e) => {
                setCompteRendu((value) => ({
                  ...value,
                  informationsPieces: {
                    ...value.informationsPieces,
                    chambre: {
                      ...value.informationsPieces.chambre,
                      detail: e.target.value,
                    },
                  },
                }))
              }}
              name='Chambre'
              id='Chambre'
              value={compteRendu.informationsPieces.chambre.detail}
              placeholder='Caractéristiques,état,rangements...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          {/* Autres espaces */}
          <h3 className='underline my-3 '>Autres espaces</h3>
          <div className='flex gap-1'>
            <span>Jardin:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.haveJardin === true}
                onChange={() => handleChangeValue(true, 'haveJardin', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.haveJardin === false}
                onChange={() => handleChangeValue(false, 'haveJardin', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          {compteRendu.otherSpaces.haveJardin && (
            <>
              <div className='flex gap-1'>
                <label htmlFor='numberApp'>Superficie</label>{' '}
                <div className='flex gap-1'>
                  <input
                    disabled={!authState.isVisiteur}
                    onChange={(e) =>
                      handleChangeValue(e.target.value, 'jardinSuperficie', 'otherSpaces')
                    }
                    value={compteRendu.otherSpaces.jardinSuperficie}
                    className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
                    type='number'
                    id=''
                    maxLength={2}
                    min={0}
                  />
                  <span>m2</span>
                </div>
              </div>
              <div className='flex gap-1'>
                <span>Vis à vis:</span>{' '}
                <div>
                  <label htmlFor='toggleLogementType'>Oui</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.visAvis === true}
                    onChange={() => handleChangeValue(true, 'visAvis', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
                <div>
                  <label htmlFor='toggleLogementType'>Non</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.visAvis === false}
                    onChange={() => handleChangeValue(false, 'visAvis', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
              </div>
              <div className='flex gap-1'>
                <span>Clôture:</span>{' '}
                <div>
                  <label htmlFor='toggleLogementType'>Oui</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.cloture === true}
                    onChange={() => handleChangeValue(true, 'cloture', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
                <div>
                  <label htmlFor='toggleLogementType'>Non</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.cloture === false}
                    onChange={() => handleChangeValue(false, 'cloture', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
              </div>
              <div className='flex gap-1'>
                <span>Arboré:</span>{' '}
                <div>
                  <label htmlFor='toggleLogementType'>Oui</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.arboré === true}
                    onChange={() => handleChangeValue(true, 'arboré', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
                <div>
                  <label htmlFor='toggleLogementType'>Non</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.arboré === false}
                    onChange={() => handleChangeValue(false, 'arboré', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
              </div>
              <div className='max-h-[200px]'>
                <textarea
                  disabled={!authState.isVisiteur}
                  value={compteRendu.otherSpaces.otherDetail}
                  onChange={(e) => handleChangeValue(e.target.value, 'otherDetail', 'otherSpaces')}
                  name='otherInfos'
                  id='otherInfos'
                  placeholder='Autres caractéristiques...'
                  className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
                />
              </div>
            </>
          )}
          <div className='flex gap-1'>
            <span>Cave:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.cave === true}
                onChange={() => handleChangeValue(true, 'cave', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.cave === false}
                onChange={() => handleChangeValue(false, 'cave', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          {compteRendu.otherSpaces.cave && (
            <>
              <div className='flex gap-1'>
                <label htmlFor='numberApp'>Superficie</label>{' '}
                <div className='flex gap-1'>
                  <input
                    disabled={!authState.isVisiteur}
                    onChange={(e) =>
                      handleChangeValue(e.target.value, 'superficieCave', 'otherSpaces')
                    }
                    className=' border-b border-gray-300 min-w-[32px]  max-w-[45px] text-center'
                    type='number'
                    id=''
                    maxLength={3}
                    value={compteRendu.otherSpaces.superficieCave}
                    min={0}
                  />
                  <span>m2</span>
                </div>
              </div>
              <div className='max-h-[200px]'>
                <textarea
                  disabled={!authState.isVisiteur}
                  name='otherInfos'
                  id='otherInfos'
                  value={compteRendu.otherSpaces.detailCave}
                  onChange={(e) => handleChangeValue(e.target.value, 'detailCave', 'otherSpaces')}
                  placeholder='Etat général,accessibilité...'
                  className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
                />
              </div>
            </>
          )}
          <div className='flex gap-1'>
            <span>Parking:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.parking === true}
                onChange={() => handleChangeValue(true, 'parking', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.otherSpaces.parking === false}
                onChange={() => handleChangeValue(false, 'parking', 'otherSpaces')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          {compteRendu.otherSpaces.parking && (
            <>
              <div className='flex gap-1'>
                <span>Numéroté:</span>{' '}
                <div>
                  <label htmlFor='toggleLogementType'>Oui</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.parkingHaveNum === true}
                    onChange={() => handleChangeValue(true, 'parkingHaveNum', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
                <div>
                  <label htmlFor='toggleLogementType'>Non</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.parkingHaveNum === false}
                    onChange={() => handleChangeValue(false, 'parkingHaveNum', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
              </div>
              <div className='flex gap-1'>
                <span>Sécurisé:</span>{' '}
                <div>
                  <label htmlFor='toggleLogementType'>Oui</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.parkingIsSecur === true}
                    onChange={() => handleChangeValue(true, 'parkingIsSecur', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
                <div>
                  <label htmlFor='toggleLogementType'>Non</label>{' '}
                  <input
                    disabled={!authState.isVisiteur}
                    checked={compteRendu.otherSpaces.parkingIsSecur === false}
                    onChange={() => handleChangeValue(false, 'parkingIsSecur', 'otherSpaces')}
                    className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                    type='checkbox'
                    id='toggleLogementType'
                  />
                </div>
              </div>

              <div className='max-h-[200px]'>
                <textarea
                  disabled={!authState.isVisiteur}
                  value={compteRendu.otherSpaces.parkingDetail}
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 'parkingDetail', 'otherSpaces')
                  }
                  name='otherInfos'
                  id='otherInfos'
                  placeholder='Accessibilité,éclairage...'
                  className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
                />
              </div>
            </>
          )}
          {/* Environnement */}
          <h3 className='underline my-3 '>Environnement</h3>
          <div className='flex gap-1'>
            <span>Trafic:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Passant</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.trafic === 'passant'}
                onChange={() => handleChangeValue('passant', 'trafic', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Calme</label>{' '}
              <input
                checked={compteRendu.environnement.trafic === 'calme'}
                onChange={() => handleChangeValue('calme', 'trafic', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Nuisances:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Sonores</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.nuissances.includes('sonores')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances, 'sonores'],
                      },
                    }))
                  } else {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances].filter(
                          (val) => val !== 'sonores',
                        ),
                      },
                    }))
                  }
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Visuelles</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.nuissances.includes('visuelles')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances, 'visuelles'],
                      },
                    }))
                  } else {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances].filter(
                          (val) => val !== 'visuelles',
                        ),
                      },
                    }))
                  }
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Offactives</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.nuissances.includes('offactives')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances, 'offactives'],
                      },
                    }))
                  } else {
                    setCompteRendu((value) => ({
                      ...value,
                      environnement: {
                        ...value.environnement,
                        nuissances: [...value.environnement.nuissances].filter(
                          (val) => val !== 'offactives',
                        ),
                      },
                    }))
                  }
                }}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Facilité de stationnement:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.easyStationnement === true}
                onChange={() => handleChangeValue(true, 'easyStationnement', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.easyStationnement === false}
                onChange={() => handleChangeValue(false, 'easyStationnement', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Proximité des transports:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.nearTransport === true}
                onChange={() => handleChangeValue(true, 'nearTransport', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.nearTransport === false}
                onChange={() => handleChangeValue(false, 'nearTransport', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Commerces:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.commerces === true}
                onChange={() => handleChangeValue(true, 'commerces', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.commerces === false}
                onChange={() => handleChangeValue(false, 'commerces', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Réseau téléphonique:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Bon</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.reseauDetail === 'bon'}
                onChange={() => handleChangeValue('bon', 'reseauDetail', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Moyen</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.reseauDetail === 'moyen'}
                onChange={() => handleChangeValue('moyen', 'reseauDetail', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Mauvais</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.reseauDetail === 'mauvais'}
                onChange={() => handleChangeValue('mauvais', 'reseauDetail', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='flex gap-1'>
            <span>Vis à vis:</span>{' '}
            <div>
              <label htmlFor='toggleLogementType'>Oui</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.visAvis === true}
                onChange={() => handleChangeValue(true, 'visAvis', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
            <div>
              <label htmlFor='toggleLogementType'>Non</label>{' '}
              <input
                disabled={!authState.isVisiteur}
                checked={compteRendu.environnement.visAvis === false}
                onChange={() => handleChangeValue(false, 'visAvis', 'environnement')}
                className='appearance-none bg-transparent border border-gray-600 rounded w-3.5 h-3.5 checked:bg-green-400 focus:outline-none'
                type='checkbox'
                id='toggleLogementType'
              />
            </div>
          </div>
          <div className='max-h-[200px]'>
            <textarea
              disabled={!authState.isVisiteur}
              value={compteRendu.environnement.otherDetail}
              onChange={(e) => handleChangeValue(e.target.value, 'otherDetail', 'environnement')}
              name='otherInfos'
              id='otherInfos'
              placeholder="Informations complémentaires sur l'environnement ..."
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
          <span>Demandes spécifiques</span>
          <div className='max-h-[200px] '>
            <textarea
              disabled={!authState.isVisiteur}
              value={compteRendu.environnement.demandesSpecifique}
              onChange={(e) =>
                handleChangeValue(e.target.value, 'demandesSpecifique', 'environnement')
              }
              name='otherInfos'
              id='otherInfos'
              placeholder='Vos commentaires...'
              className='border border-gray-300 p-1 h-[35px]  max-h-[200px] max-w-[60%] resize min-h-[35px] min-w-[60%] '
            />
          </div>
        </div>

        {/* rigth */}
        <div className=' border-b border-gray-200   w-full pb-5 mb-2 md:w-[30%] md:pb-0 md:mb-0  md:border-b-0 md:border-l'>
          <div className='flex md:flex-col justify-between h-full gap-2  pl-[5%] xl:pl-[20%]'>
            <div className='flex flex-col-reverse gap-2 md:block'>
              {authState.isVisiteur && (
                <div className='sm:flex  items-center '>
                  <button className='relative hover:scale-105'>
                    <span className='block  border-2 border-gray-500 bg-[#fddfcc] rounded-lg '>
                      <AddIcon className='text-gray-500 w-20 h-20' />
                    </span>
                    <input
                      className='absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer'
                      type='file'
                      multiple
                      onChange={handleFileChange}
                    />
                  </button>
                  <span className='text-gray-600 font-semibold text-xs md:text-sm'>
                    Ajouter fichier
                  </span>
                </div>
              )}
              {compteRendu.fichiers.length > 0 && (
                <div className='overflow-x-auto whitespace-nowrap p-2 '>
                  <div className='flex '>
                    {compteRendu.fichiers.map((file: any, index: any) => (
                      <div
                        onClick={() => setSelectedFile({ ...file, index })}
                        className='flex-shrink-0 mr-2 cursor-pointer flex-col gap-1 relative shadow-md border-t border-gray-200 border-opacity-50 hover:scale-[1.02] transtion-all duration-100'
                        key={index}
                      >
                        {authState.isVisiteur && (
                          <button
                            className='absolute top-0.5 right-1 bg-white rounded-full px-1 py-0 border border-gray-400 hover:bg-black hover:text-white transtion-all duration-150'
                            onClick={(e) => {
                              e.stopPropagation()
                              setCompteRendu((value) => ({
                                ...value,
                                fichiers: compteRendu.fichiers.filter(
                                  (f: any, i: any) => i !== index,
                                ),
                              }))
                            }}
                          >
                            <span className='transform translate-y-[-10%] text-[10px] '>x</span>
                          </button>
                        )}
                        <img className='w-32 h-40 object-cover  ' src={file.url} alt={file.name} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className='flex flex-wrap gap-2 mt-3   items-center'>
                <img
                  className='w-14 h-14 object-cover border border-gray-200 rounded-full'
                  src={chat.otherUser.userImg || etourneau}
                  alt='imguser'
                />
                <span className='font-semibold text-gray-700'>
                  {chat.otherUser.firstname}{' '}
                  <span className='uppercase'>{chat.otherUser.lastname[0]}</span>
                </span>
              </div>
            </div>

            {authState.isVisiteur && (
              <>
                {dataVisite.compte_rendu.isSubmit ? (
                  <div className='flex items-center justify-center w-full'>
                    <button onClick={submitData} className='btn btn-orange bg-orange w-fit'>
                      Modifier
                    </button>
                  </div>
                ) : (
                  <div className='flex flex-col lg:flex-row'>
                    <div className='flex items-center justify-center w-full'>
                      <button
                        onClick={() => {
                          submitData({ isSubmit: true })
                        }}
                        className='btn btn-orange bg-orange w-fit'
                      >
                        Envoyer
                      </button>
                    </div>
                    <div className='flex items-center justify-center w-full -mt-6 lg:mt-0'>
                      <button
                        onClick={() => {
                          submitData({ haveData: true })
                        }}
                        className='btn btn-orange bg-orange w-fit max-w-[95.23px]'
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withAuth(compteRenduVisiteur)
