import React, { useState, useRef, useEffect } from 'react'
import logo from '../../assets/images/logo_final/logo.png'
import Plume from '../../assets/images/plume.png'
import PlumeRouge from '../../assets/images/plume-rouge.png'
import bulle from '../../assets/images/bulle.png'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseIcon from '@mui/icons-material/Close'
import './Menu.css'
import { io } from 'socket.io-client'
import { Search, ArrowDropDown, MenuOutlined, Close } from '@mui/icons-material'

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import etourneau from '../../assets/images/image_oiseaux/etourneau.png'
import {
  deleteNotification,
  getNotifications,
  setNotificationWatched,
} from '../../api/Notifications'
import { verifyInfosAccountStripe } from '../../api/Paiement'

interface MenuProps {
  authState: {
    email: string
    connectedAccountID: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    isVerifiedStripeAccount: boolean
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const Menu: React.FC<MenuProps> = ({ authState, authProps }) => {
  const { isLoggedIn, setIsLoggedIn } = authProps
  const [menuOpen, setMenuOpen] = useState(false)
  const [openBigMenu, setOpenBigMenu] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isProfileVisible, setIsProfileVisible] = useState(false) // État pour la visibilité du profil
  const profileCardRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const modalNotifRef = useRef<HTMLDivElement>(null)
  const [showTooltipNotVerified, setShowTooltipNotVerified] = useState(false)
  const [notifs, setNotifs] = useState<any[]>([])
  const [openNotifs, setOpenNotifs] = useState(false)
  const buttonNotifRef = useRef<HTMLButtonElement>(null)
  const [identityNotVerified, setIdentityNotVerified] = useState(false)
  const [stripeAccountTosAccepted, setStripeAccountTosAccepted] = useState(false)
  const [loadedStripeInfos, setLoadedStripeInfos] = useState(false)

  const serverAppDomain = process.env.REACT_APP_WEBSOCKET_URL
  const socket = io(serverAppDomain as string, { transports: ['websocket'] })

  const checkInfosStripeAccount = async () => {
    if (authState?.connectedAccountID) {
      const res = await verifyInfosAccountStripe(authState?.connectedAccountID)
      if (res) {
        setLoadedStripeInfos(true)
        if (res.data.tos?.date) setStripeAccountTosAccepted(true)
        if (res.data.refresh) setTimeout(() => window.location.reload(), 50)
        res.data.due.forEach((due: string) => {
          if (due === 'individual.verification.document') setIdentityNotVerified(true)
        })
      }
    }
  }

  useEffect(() => {
    checkInfosStripeAccount()
  }, [authState.connectedAccountID])

  const notifIsWatched = async (notifId: string) => {
    await setNotificationWatched(authProps.token, notifId)
  }
  const handleClickOutside = (event: any) => {
    if (
      buttonNotifRef?.current &&
      !buttonNotifRef?.current?.contains(event.target) &&
      modalNotifRef?.current &&
      !modalNotifRef?.current?.contains(event.target)
    ) {
      setOpenNotifs(false)
    }

    if (buttonRef?.current && !buttonRef?.current?.contains(event.target)) {
      setIsProfileVisible(false)
    }
  }
  const deleteNotif = async (notifId: string) => {
    const res = await deleteNotification(authProps.token, notifId)
    if (res.success) {
      fetchNotifications()
    }
  }
  const fetchNotifications = async () => {
    if (authState.email) {
      const notifications = await getNotifications(authProps.token, authState.email)
      if (notifications.success) {
        setNotifs(notifications.notifications)
      }
    }
  }

  useEffect(() => {
    if (authState.email) {
      socket.emit('newUser', authState.email)
      fetchNotifications()
    }
  }, [authState.email])

  useEffect(() => {
    setNotifs(notifs)
  }, [notifs])

  useEffect(() => {
    socket.on('newNotif', (data: any) => {
      if (data.userEmail === authState.email) setNotifs([data, ...notifs])
    })
  }, [notifs])

  const handleResize = () => {
    if (window.innerWidth > 800) setOpenBigMenu(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const toggleProfile = () => {
    if (openNotifs) setOpenNotifs(false)
    setIsProfileVisible(!isProfileVisible)
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const windowHeight = window.innerHeight
    if (scrollPosition > windowHeight * 0.1) {
      setIsScrolling(true)
    } else {
      setIsScrolling(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <header
        className={`fixed z-20 py-1 w-full px-6  h-[70px]   flex justify-between top-0 left-1/2 transform -translate-x-1/2 ${
          menuOpen ? 'active' : ''
        }  ${
          isScrolling
            ? ' transition-all duration-800 shadow-xl     bg-opacity-[70%]    w-full'
            : ` ${authProps.isLoggedIn ? 'bg-[#FDDFCC]' : ' '}  transition-all duration-800`
        }`}
      >
        {isScrolling && (
          <div className='fixed top-0 left-0 bg-white bg-opacity-50 w-full h-[70px] blur z-1 transition-all duration-800'></div>
        )}
        {authProps.isLoggedIn && (
          <div className='fixed top-0 left-0 w-full overflow-hidden  h-[70px] '>
            <div
              className={`w-[350px] rounded-full h-[330px] absolute -right-10 top-10 transition-all duration-800 ${
                isScrolling ? '  ' : '  bg-[#FCC9AA]'
              } `}
            ></div>
          </div>
        )}

        <div className='flex items-center'>
          <div className=' z-10'>
            <a href='/'>
              <img src={logo} alt='Logo' className='w-16' />
            </a>
          </div>

          <input
            className='menu-btn hidden'
            type='checkbox'
            id='menu-btn'
            checked={menuOpen}
            onChange={toggleMenu}
          />
          <label className='menu-icon' htmlFor='menu-btn'>
            <span className='navicon'></span>
          </label>
        </div>

        <ul className=' flex items-center justify-center gap-12 absolute -top-[1000px] md:relative md:top-0'>
          <li className='relative font-semibold'>
            <a href='/programmation-visite' className='reset-active menu-li-hover'>
              <Search /> Rechercher un visiteur
            </a>
            <div
              className={`${
                window.location.pathname === '/programmation-visite'
                  ? 'border-b-[3px]  w-full  absolute -bottom-[6px] transfrom translate-x-1  border-[#f75d00]'
                  : ''
              }`}
            ></div>
          </li>
          {!authState.isVisiteur ? (
            <li className='relative h-[25.64px] font-semibold'>
              <a href='/inscription-visiteur' className='menu-li-hover  '>
                <img src={Plume} alt='plume' className='w-6  absolute -left-[26px] top-2   ' />{' '}
                Devenir visiteur
              </a>
              <div
                className={`${
                  window.location.pathname === '/inscription-visiteur'
                    ? 'border-b-[3px]  w-[120%]  absolute -bottom-[5px] transfrom translate-x-[-15%] border-[#f75d00]'
                    : ''
                }`}
              ></div>
            </li>
          ) : (
            <li className='relative h-[25.64px]'>
              <div className='text-[#f40000]   font-medium'>
                <img src={PlumeRouge} alt='plume' className='w-8 absolute -left-[30px] -top-0.5 ' />
                Vous êtes visiteur
                {loadedStripeInfos && (
                  <>
                    {' '}
                    {(identityNotVerified || !stripeAccountTosAccepted) && (
                      <>
                        <span
                          onClick={() => (window.location.href = '/dashboard')}
                          onMouseEnter={() => {
                            setShowTooltipNotVerified(true)
                          }}
                          onMouseLeave={() => {
                            setShowTooltipNotVerified(false)
                          }}
                        >
                          {' '}
                          <ErrorOutlineIcon className=' cursor-pointer text-orange-400 mb-1 animate-pulse' />
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              {showTooltipNotVerified && (
                <>
                  {identityNotVerified ? (
                    <div className='absolute flex  justify-center items-center bottom-0 right-0 transfrom translate-y-[100%] translate-x-[50%] bg-white p-4 min-w-[200px] rounded-lg shadow-lg'>
                      Merci de faire vérifier votre identité auprès de notre partenaire <a href='/dashboard'>Stripe</a>
                    </div>
                  ) : (
                    <div className='absolute flex  justify-center items-center bottom-0 right-0 transfrom translate-y-[100%] translate-x-[50%] bg-white p-4 min-w-[200px] rounded-lg shadow-lg'>
                      Compte stripe non validé, merci de valider votre compte auprès de <a href='/dashboard'>stripe
                      dans le dashboard.</a>
                    </div>
                  )}
                </>
              )}
            </li>
          )}
        </ul>
        <div className='absolute -top-[1000px] md:relative md:top-0 md:flex    justify-center items-center gap-2'>
          <div className='user-picture-content'>
            {isLoggedIn && (
              <div className='flex gap-1 justify-center items-center'>
                <button
                  className='user-picture  flex gap-1 justify-center items-center '
                  onClick={toggleProfile}
                  ref={buttonRef}
                >
                  <img src={authState.userImg ? authState.userImg : etourneau} alt='' />

                  <span className=' first-letter:uppercase    font-semibold '>
                    {authState.firstname}
                  </span>
                  <ArrowDropDown className='transform -translate-x-1  ' />
                </button>

                <button ref={buttonNotifRef} className='p-0 relative'>
                  {notifs?.filter((notif) => !notif.isWatched).length > 0 && (
                    <div className='absolute -top-3 right-0 rounded-full px-1.5 py-0.5  bg-green-400 bg-opacity-90  flex justify-center items-center'>
                      <span className='text-[11px] text-gray-800 '>
                        {notifs?.filter((notif) => !notif.isWatched).length}
                      </span>
                    </div>
                  )}
                  <img
                    onClick={() => {
                      if (isProfileVisible) setIsProfileVisible(false)
                      setOpenNotifs(!openNotifs)
                    }}
                    src={bulle}
                    alt=''
                    className='w-8 transform -translate-x-3 opacity-70 hover:opacity-100 cursor-pointer transition-opacity '
                  />
                </button>

                <NotificationsActiveIcon className='transform -translate-x-1 opacity-60 hover:opacity-100 cursor-pointer transition-opacity ' />
              </div>
            )}

            <div
              className={`bg-white rounded-lg px-3 py-3 shadow-lg border-2 border-gray-100 flex flex-col gap-2 top-[90px]  fixed right-3 max-h-[300px] overflow-auto  ${
                openNotifs ? '' : 'hidden'
              }`}
              ref={modalNotifRef}
            >
              {notifs.length > 0 ? (
                <>
                  {notifs.map((notif: any) => (
                    <div
                      onClick={() => {
                        window.location.href = `/dashboard?chatId=${notif.content.chatId}`
                        notifIsWatched(notif.notifId)
                      }}
                      key={notif.id}
                    >
                      <div
                        className={`flex gap-5 pb-1 ${
                          notif.isWatched ? '' : 'bg-green-100 bg-opacity-60'
                        } items-center justify-between rounded-md px-2 mb-1 menu-li-hover  cursor-pointer`}
                      >
                        <div className='flex'>
                          <img
                            src={notif.content.senderImg ? notif.content.senderImg : etourneau}
                            alt={`${notif.senderEmail}'s profile`}
                            className='w-10 h-10 rounded-full object-cover mr-4'
                          />
                          <p className='text-sm font-semibold uppercase -ml-2'>
                            {notif?.content?.firstName ? notif?.content?.firstName[0] : ''}
                            {notif?.content?.lastName ? notif?.content?.lastName[0] : ''}
                          </p>
                        </div>
                        <div className='text-center flex flex-col gap-1'>
                          {/* p elem  si nouveau message ou nouvelle visite  */}

                          <p className='font-semibold  text-xs'>
                            {notif.content.type === 'message' || notif.content.type === 'Visite' ? (
                              <>
                                {!notif.isWatched
                                  ? notif.content.type === 'message'
                                    ? 'Nouveau'
                                    : 'Nouvelle'
                                  : ''}{' '}
                                {notif.content.type === 'message' ? (
                                  'message:'
                                ) : (
                                  <span>{`${notif.isWatched ? 'D' : 'd'}`}emande de visite</span>
                                )}
                              </>
                            ) : (
                              <> {notif.content.content}</>
                            )}
                          </p>

                          {notif.content.content && notif.content.type === 'message' && (
                            <p className='text-gray-600 max-w-[180px] truncate min-w-[100px]'>
                              {notif.content.content}
                            </p>
                          )}
                        </div>
                        <button
                          className='border bg-white border-gray-300 p-1.5  hover:bg-gray-50 hover:border-gray-400 transition-all duration-100 text-gray-600 hover:text-gray-800'
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteNotif(notif.notifId)
                          }}
                        >
                          <CloseIcon style={{ fontSize: 14 }} />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className='p-10 font-semibold'>Aucun message</p>
              )}
            </div>

            <div
              className={`user-picture-info fixed border-2 border-gray-100 ${
                isProfileVisible ? '' : 'hidden'
              }`}
              ref={profileCardRef}
            >
              {authState.isVisiteur && (
                <li>
                  <a href='/profil-visiteur' className='reset-active menu-li-hover '>
                    Mes paramètres
                  </a>
                </li>
              )}
              <li>
                <a href='/programmation-visite' className='menu-li-hover'>
                  Planifier une visite
                </a>
              </li>
              <li>
                <a href='/dashboard' className='menu-li-hover  '>
                  Dashboard
                </a>
              </li>

              <li>
                <span
                  className='menu-li-hover cursor-pointer'
                  onClick={() => {
                    setIsProfileVisible(false)
                    localStorage.removeItem('token')
                    setIsLoggedIn(false)
                    window.location.reload()
                  }}
                >
                  Déconnexion
                </span>
              </li>
            </div>
          </div>
          {!isLoggedIn && (
            <li className='list-none'>
              <a href='/inscription' data-testid='button-inscription'>
                <button className='start-button bg-orange '>Connexion</button>
              </a>
            </li>
          )}
        </div>
        <div className='flex  justify-center items-center md:absolute md:-top-[1000px] fixed right-4 top-6 z-10 '>
          {!openBigMenu ? (
            <MenuOutlined
              onClick={() => setOpenBigMenu(!openBigMenu)}
              className='transform -translate-x-1  cursor-pointer transition-all duration-1000 '
            />
          ) : (
            <Close
              onClick={() => setOpenBigMenu(!openBigMenu)}
              className='transform -translate-x-1  cursor-pointer transition-all duration-1000  '
            />
          )}
        </div>
      </header>
      {openBigMenu && (
        <div className=' fixed left-0 top-0 h-screen w-screen z-10 bg-opacity-90  bg-white flex justify-center items-center  '>
          <ul className='text-2xl flex flex-col gap-16 text-center justify-center items-center  '>
            {!authState.isVisiteur ? (
              <li className='relative h-[25.64px] ml-8 '>
                <a href='/inscription-visiteur' className='menu-li-hover  '>
                  <img src={Plume} alt='plume' className='w-7  absolute -left-[30px] top-3   ' />{' '}
                  Devenir visiteur
                </a>
                <div
                  className={`${
                    window.location.pathname === '/inscription-visiteur'
                      ? 'border-b-[3px]  w-[120%]  absolute -bottom-[5px] transfrom translate-x-[-15%] border-[#f75d00]'
                      : ''
                  }`}
                ></div>
              </li>
            ) : (
              <li className='relative h-[25.64px] ml-8 '>
                <div className='text-[#f40000] '>
                  <img
                    src={PlumeRouge}
                    alt='plume'
                    className='w-8 absolute -left-[30px] top-0.5 '
                  />
                  Vous êtes visiteur
                </div>
              </li>
            )}
            <li>
              <a href='/programmation-visite' className='reset-active menu-li-hover'>
                <Search /> Rechercher un visiteur
              </a>
            </li>

            {isLoggedIn && (
              <>
                {authState.isVisiteur && (
                  <li>
                    <a href='/profil-visiteur' className='reset-active menu-li-hover '>
                      Mes paramètres
                    </a>
                  </li>
                )}
                <li>
                  <a href='/programmation-visite' className='menu-li-hover'>
                    Planifier une visite
                  </a>
                </li>
                <li>
                  <a href='/dashboard' className='menu-li-hover  '>
                    Dashboard
                  </a>
                </li>

                <li>
                  <a href='#' className='menu-li-hover  '>
                    Infos
                  </a>
                </li>
              </>
            )}

            {!isLoggedIn ? (
              <li className='list-none'>
                <a href='/inscription'>
                  <button className='start-button bg-orange '>Connexion</button>
                </a>
              </li>
            ) : (
              <li>
                <span
                  className='menu-li-hover cursor-pointer'
                  onClick={() => {
                    setIsProfileVisible(false)
                    localStorage.removeItem('token')
                    setIsLoggedIn(false)
                    window.location.reload()
                  }}
                >
                  Déconnexion
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  )
}

export default Menu
