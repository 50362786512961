import React from 'react';

interface SummaryProps {
    revenus: {
        sommeTotal: number;
        annee: number;
        TotalDemandeDeVisites: number;
        TotalDeDemandeDeVisitesRealises: number;
        TotalDeDemandeDeVisitesRefusees: number;
        TotalDeDemandeDeVisitesAccepetes: number;
        TotalRemboursement: number;
    } | undefined;
    paiements: {
        sommeTotalDepenses: number;
        annee: number;
        TotalDeDemandeDeVisitesRealises: number;
        TotalDeDemandeDeVisitesRefusees: number;
        TotalRemboursement: number;
    }| undefined;
}

const Portefeuille: React.FC<SummaryProps> = ({ revenus, paiements }) => {
    return (
        <>
        <div>Portefeuille 2024</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            <div style={{flex: 1, marginRight: '10px'}}>
                <h2>Mes revenus</h2>
                <p><strong>Total : </strong> {revenus?.sommeTotal ?? 0} €</p>
                <p>Revenus générés en {revenus?.annee} </p>
                <br/>
                <p><strong>Demande de visite :</strong> {revenus?.TotalDemandeDeVisites}</p>
                <p><strong>Demandes de visites réalisées : </strong> {revenus?.TotalDeDemandeDeVisitesRealises}</p>
                <br/>
                <p>Visites refusées : {revenus?.TotalDeDemandeDeVisitesRefusees}</p>
                <p>Visites acceptées :{revenus?.TotalDeDemandeDeVisitesAccepetes}</p>
                <p>Visites annulées : {revenus?.TotalDeDemandeDeVisitesRefusees}</p>
                <p>Remboursement : {revenus?.TotalRemboursement} €</p>
            </div>

            <div style={{ flex: 1, marginLeft: '10px' }}>
                <h2>Mes paiements</h2>
                <p><strong>Total :</strong> {paiements?.sommeTotalDepenses} €</p>
                <p>Revenus générés en {paiements?.annee}</p>
                <br/>
                <p>Visites réalisées : {paiements?.TotalDeDemandeDeVisitesRealises}</p>
                <p>Visites annulées : {paiements?.TotalDeDemandeDeVisitesRefusees}</p>
                <p>Remboursement : {paiements?.TotalRemboursement} €</p>
            </div>
        </div>
        </>
    );
};

export default Portefeuille;
