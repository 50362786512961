/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import {getAllVisiteurs, getAllVisiteursAroundCity} from '../../../api/ProfilVisiteur'
import '../index.css'
import etourneau from '../../../assets/images/image_oiseaux/etourneau.png'
import { ProfilType } from '../Profil.type'
import CloseIcon from '@mui/icons-material/Close'
interface FormDataProps {
  formData: {
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    typeBien: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    selectedProfiles: ProfilType[]
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      date: Date
      heureVisite: string
      proprietaire: string
      adresseBien: string
      ville: string
      codePostal: string
      typeBien: string
      prix: { email: string; prix: number }[]
      informationsComplementaires: string
      selectedProfiles: ProfilType[]
    }>
  >
  formDataValid: any
  setFormDataValid: React.Dispatch<React.SetStateAction<any>>
  token: string
  userEmail: string
}

export default function Part3({
  formData,
  setFormData,
  formDataValid,
  setFormDataValid,
  token,
  userEmail,
}: FormDataProps) {
  const [selectedProfile, setSelectedProfile] = useState<number[]>([])
  const [listProfilVisiteur, setListProfilVisiteur] = useState<ProfilType[]>([])
  const [selectedOption, setSelectedOption] = useState('')
  const [userInfos, setUserInfos] = useState(null) as any

  const showInfosProfil = (e: any, user: any) => {
    e.stopPropagation()
    setUserInfos(user)
  }

  const handleProfileClick = (profil: ProfilType, index: number) => {
    setSelectedProfile((stat) => {
      // Vérifier si l'index n'existe pas déjà dans le tableau
      if (!stat.includes(index)) {
        // Si l'index n'existe pas, ajoutez-le au tableau
        return [...stat, index]
      } else {
        // Si l'index existe déjà, enlevez-le du tableau
        return stat.filter((item) => item !== index)
      }
    })

    setFormDataValid(true)
    setFormData((prevFormData) => {
      const updatedSelectedProfiles = [...prevFormData.selectedProfiles]

      // Vérifier si le profil existe déjà dans la liste
      const profileIndex = updatedSelectedProfiles.findIndex((item) => item.id === profil.id)

      if (profileIndex === -1) {
        // Si le profil n'existe pas, l'ajouter à la liste
        updatedSelectedProfiles.push(profil)
      } else {
        // Si le profil existe, le retirer de la liste
        updatedSelectedProfiles.splice(profileIndex, 1)
      }

      // Mettre à jour formData avec la nouvelle liste de profils sélectionnés
      return { ...prevFormData, selectedProfiles: updatedSelectedProfiles }
    })
  }

  useEffect(() => {
    try {
      console.log(formData.codePostal);
      getAllVisiteursAroundCity(token, userEmail,formData.codePostal).then((res) => {
        setListProfilVisiteur(res.visiteurList)
      })


    } catch (error) {
      console.error('Erreur lors de la création du profil visiteur', error)
    }
  }, [])

  const sortProfilVisiteurs = (option: string) => {
    if (option === 'mieux_note') {
      return listProfilVisiteur
        .slice()
        .sort((a, b) => (b.profileVisiteur.note as number) - (a.profileVisiteur.note as number))
    }

    if (option === 'tarif_croissant') {
      return listProfilVisiteur
        .slice()
        .sort(
          (a, b) =>
            (new Date(a.profileVisiteur.tarif) as any) - (new Date(b.profileVisiteur.tarif) as any),
        )
    }

    if (option === 'tarif_decroissant') {
      return listProfilVisiteur
        .slice()
        .sort(
          (a, b) =>
            (new Date(b.profileVisiteur.tarif) as any) - (new Date(a.profileVisiteur.tarif) as any),
        )
    }

    // Si l'option n'est pas reconnue, retournez le tableau non trié par défaut
    return listProfilVisiteur.slice()
  }

  const listProfilVisiteurTriee = sortProfilVisiteurs(selectedOption)

  return (
    <div>
      {userInfos && (
        <div className='fixed z-10 top-0 left-0 h-full w-full flex justify-center items-center bg-gray-50 bg-opacity-50 transition-all duration-[1500ms]'>
          <div className='border border-gray-100 shadow-2xl bg-white mx-2 p-8 rounded-lg relative'>
            <button
              className='absolute top-4 right-4 p-2 cursor-pointer text-gray-700 hover:text-gray-800 transition-all duration-150'
              onClick={() => setUserInfos(null)}
            >
              <CloseIcon />
            </button>

            <img
              src={userInfos.userImg ? userInfos.userImg : etourneau}
              alt={`${userInfos.nom}'s profile`}
              className='w-16 h-16 rounded-full object-cover mx-auto mb-4'
            />

            <h2 className='text-2xl font-semibold mb-2'>{userInfos.profileVisiteur.prenom}</h2>
            <p className='text-gray-700 mb-4'>{userInfos.description}</p>

            {/* Autres informations sur l'utilisateur */}
            <div className='mt-4'>
              <p className='mb-2'>
                <strong>Il parle de lui:</strong> <br />
                {userInfos.profileVisiteur.parlez_de_vous}
              </p>
              <p className='mb-2'>
                <strong>Preference d'annulation:</strong> <br />
                {userInfos.profileVisiteur.preference_annulation}
              </p>
              <p className='mb-2'>
                <strong>Preference de modification:</strong> <br />
                {userInfos.profileVisiteur.preference_modification}
              </p>
              <p className='mb-2'>
                <strong>Ville:</strong> <br /> {userInfos.profileVisiteur.ville_de_naissance}
              </p>
              <p className='mb-2'>
                <strong>Code postal:</strong> <br /> {userInfos.profileVisiteur.code_postal}
              </p>
              {/* Ajoutez d'autres informations en fonction de votre modèle d'utilisateur */}
            </div>
          </div>
        </div>
      )}
      <div className='programmation-visite-sous-titre-section'>
        <div>
          <h2 className='programmation-visite-sous-titre'>Choisir un visiteur</h2>
          <p>Choisir un visiteur qui effectuera la visite à votre place</p>
        </div>
        <div className='commentaires-utilisateurs-header'>
          <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
            <option value='mieux_note'>Mieux noté</option>
            <option value='tarif_decroissant'>Tarif décroissant</option>
            <option value='tarif_croissant'>Tarif croissant</option>
          </select>
        </div>
      </div>
      {listProfilVisiteurTriee.length > 0 ? (
        <>
          <div className='select-liste-profil'>
            {listProfilVisiteurTriee.map((profile, index) => (
              <div
                  data-testid={`select-user-${profile.profileVisiteur.prenom}`}
                key={index}
                className={`select-profil ${
                  selectedProfile.includes(index) ? 'select-profil-actif' : ''
                }`}
                onClick={() => handleProfileClick(profile, index)}
              >
                <div className='profil-info '>
                  <img src={profile.userImg ? profile.userImg : etourneau} alt='' />
                  <div>
                    <h3> {profile.profileVisiteur && profile.profileVisiteur.prenom} </h3>
                  </div>
                  <button
                    onClick={(e) => showInfosProfil(e, profile)}
                    className='flex border border-gray-300 p-2 rounded-lg hover:bg-white  transition-all duration-200 '
                  >
                    + d'infos
                  </button>
                </div>

                <div>
                  <div className='profil-rate'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='#F79009'
                    >
                      <path d='M18.3203 8.93603L14.7969 12.011L15.8524 16.5892C15.9082 16.8285 15.8923 17.079 15.8065 17.3093C15.7208 17.5396 15.5691 17.7396 15.3703 17.8841C15.1716 18.0287 14.9346 18.1114 14.6891 18.1221C14.4436 18.1327 14.2004 18.0707 13.9899 17.9438L9.99689 15.522L6.01252 17.9438C5.80202 18.0707 5.55881 18.1327 5.31328 18.1221C5.06775 18.1114 4.83079 18.0287 4.63204 17.8841C4.4333 17.7396 4.28157 17.5396 4.19584 17.3093C4.1101 17.079 4.09416 16.8285 4.15002 16.5892L5.20392 12.0157L1.67970 8.93603C1.49331 8.77526 1.35852 8.56304 1.29225 8.32598C1.22598 8.08892 1.23117 7.83757 1.30718 7.60345C1.38319 7.36933 1.52663 7.16287 1.71952 7.00994C1.91240 6.85702 2.14614 6.76445 2.39142 6.74384L7.03674 6.34149L8.85002 2.01649C8.94471 1.78955 9.10443 1.59570 9.30907 1.45935C9.51371 1.32300 9.75411 1.25024 10 1.25024C10.2459 1.25024 10.4863 1.32300 10.691 1.45935C10.8956 1.59570 11.0553 1.78955 11.15 2.01649L12.9688 6.34149L17.6125 6.74384C17.8578 6.76445 18.0915 6.85702 18.2844 7.00994C18.4773 7.16287 18.6207 7.36933 18.6968 7.60345C18.7728 7.83757 18.778 8.08892 18.7117 8.32598C18.6454 8.56304 18.5106 8.77526 18.3242 8.93603H18.3203Z' />
                    </svg>
                    <p> {profile.profileVisiteur && profile.profileVisiteur.note} </p>
                  </div>
                  <h4 className='profil-price'>
                    {profile.profileVisiteur && profile.profileVisiteur.tarif}€
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {' '}
          <h4 className='font-semibold text-orange-500 mt-2 '>Aucun visiteur dispo</h4>
        </>
      )}
    </div>
  )
}
