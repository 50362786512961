import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import withAuth from '../../Security/withAuth'
import { FormValuesType } from '../formValueType'
import { Ville } from '../Ville'
import swal from 'sweetalert'
import Autocomplete from 'react-google-autocomplete'
import { Close } from '@mui/icons-material'

interface CityType {
  insee_code: string
  city_code: string
  zip_code: string
  label: string
  latitude: string
  longitude: string
  department_name: string
  department_number: string
  region_name: string
  region_geojson_name: string
}

interface CreerProfilVisiteurProps {
  setAffichage: React.Dispatch<React.SetStateAction<boolean>>
  formValues: FormValuesType
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesType>>
  setAffichageCreerProfilSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const CreerProfilVisiteur: React.FC<CreerProfilVisiteurProps> = ({
  setAffichage,
  formValues,
  setFormValues,
  setAffichageCreerProfilSuccess,
}: CreerProfilVisiteurProps) => {
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})
  const [selectedCities, setSelectedCities] = useState<Ville[]>(formValues.villesVisisteur || [])

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target

    setFormValues({ ...formValues, [name]: value })
  }

  const adressDuBienRef = useRef(null) as any
  const handleSubmit = async () => {
    try {
      // Clear previous validation errors
      setValidationErrors({})

      // Scroll to the top of the window
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      // Check for required fields
      const requiredFields = [
        'civilite',
        'nom',
        'prenom',
        'dateDeNaissance',
        'villeDeNaissance',
        'adresse',
        'codePostal',
        'telephone',
        'email',
      ]

      const newErrors: Record<string, string> = requiredFields.reduce((errors, fieldName) => {
        if (!(formValues as any)[fieldName] || (formValues as any)[fieldName].trim() === '') {
          errors[fieldName] = 'Ce champ est obligatoire'
        }
        return errors
      }, {} as Record<string, string>)

      if (Object.keys(newErrors).length > 0) {
        // If there are validation errors, set them and return
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }))
        return
      }

      swal('Good job!', 'On continue!', 'success')
      setAffichage(false)
      setAffichageCreerProfilSuccess(true)
    } catch (error) {
      // In case of an error, display an alert message
      swal('Error', 'An error occurred while submitting the form', 'error')
      console.error(error)
    }
  }



  const handleCityClick = (selectedCity: any) => {
    const city = selectedCity.address_components[0].short_name
   console.log(selectedCity);
    const cityNew: Ville= {
        nom:selectedCity.address_components[0].short_name,
        codePostal: selectedCity.address_components[4]?.short_name
    }

    setSelectedCities((prevCities) => {
      const isCityUnique = !prevCities.find((existingCity) => existingCity === cityNew)

      if (isCityUnique) {
        return [...prevCities, cityNew]
      } else {
        swal('Erreur', 'Vous avez déjà ajouté cette ville', 'error')
        return [...prevCities]
      }
    })
  }

  const removeCity = (index: number) => {
    const updatedCities = [...selectedCities]
    updatedCities.splice(index, 1)
    setSelectedCities(updatedCities)
  }

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      villesVisisteur: selectedCities,
    }))
  }, [selectedCities, setFormValues])

  return (
    <div className='pt-32 pb-10 mx-auto w-7/12 mb-2em'>
      <h1>Créez votre profil</h1>
      <h2>Faites-vous connaître</h2>
      <div>
        <section className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg mt-5'>
          <label className='mt-10 font-bold'>Civilité *</label>
          <div>
            <select
              name='civilite'
              className='p-3 rounded-xl shadow-lg'
              onChange={handleInputChange}
              value={formValues.civilite || ''}
            >
              <option value=''>Choisir...</option>
              <option value='Monsieur'>Monsieur</option>
              <option value='Madame'>Madame</option>
            </select>
            {validationErrors.civilite && (
              <p className='text-red-500'>{validationErrors.civilite}</p>
            )}
          </div>

          <div className='my-5'>
            <label className='mt-10 font-bold'>Nom *</label>
            <input
              type='text'
              disabled={!formValues.email.includes('gmail')}
              name='nom'
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.nom || ''}
              onChange={handleInputChange}
            />
            {validationErrors.nom && <p className='text-red-500'>{validationErrors.nom}</p>}
          </div>

          <div className='my-5'>
            <label className='mt-10 font-bold'>Prénom *</label>
            <input
              type='text'
              disabled={!formValues.email.includes('gmail')}
              name='prenom'
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.prenom || ''}
              onChange={handleInputChange}
            />
            {validationErrors.prenom && <p className='text-red-500'>{validationErrors.prenom}</p>}
          </div>
        </section>

        <section className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg'>
          <div className='my-5'>
            <label className='mt-10 font-bold'>Date de naissance *</label>
            <input
              type='date'
              name='dateDeNaissance'
              className='rounded-full outline-none pl-3 w-full p-5 cursor-pointer mt-1 py-2 mb-1'
              value={new Date(formValues.dateDeNaissance).toISOString().split('T')[0] || ''}
              onChange={handleInputChange}
            />
            {validationErrors.dateDeNaissance && (
              <p className='text-red-500'>{validationErrors.dateDeNaissance}</p>
            )}
          </div>

          <div className='my-5'>
            <label className='mt-10 font-bold'>Ville de naissance *</label>
            <input
              type='text'
              name='villeDeNaissance'
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.villeDeNaissance || ''}
              onChange={handleInputChange}
            />
            {validationErrors.villeDeNaissance && (
              <p className='text-red-500'>{validationErrors.villeDeNaissance}</p>
            )}
          </div>

          <div className='my-5'>
            <label className='mt-10 font-bold'>Adresse postale *</label>
            <div className='rounded-full  w-full  pl-3 mt-1 py-2 mb-1 bg-white '>
              <Autocomplete
                ref={adressDuBienRef}
                apiKey={process.env.REACT_APP_GOOGLE}
                onPlaceSelected={(place) => {
                  const codePostalObj = place.address_components.find(
                    (a: any) => a.types[0] === 'postal_code',
                  )
                  const codePostal = codePostalObj ? codePostalObj.short_name : ''
                  const adresse = place.formatted_address.split(',')[0]
                  setFormValues((val) => ({ ...val, codePostal, adresse }))

                  adressDuBienRef.current.value = adressDuBienRef.current.value.split(',')[0]
                }}
                options={{
                  types: ['route'],
                  componentRestrictions: { country: 'fr' },
                }}
              />
            </div>
            {validationErrors.adresse && <p className='text-red-500'>{validationErrors.adresse}</p>}
          </div>

          <div className='my-5'>
            <label className='mt-10 font-bold'>Code postal *</label>
            <input
              type='text'
              name='codePostal'
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.codePostal || ''}
              onChange={handleInputChange}
            />
            {validationErrors.codePostal && (
              <p className='text-red-500'>{validationErrors.codePostal}</p>
            )}
          </div>
        </section>

        <section className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg'>
          <div className='my-5'>
            <label className='mt-10 font-bold'>Téléphone *</label>
            <input
              type='tel'
              name='telephone'
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.telephone || ''}
              onChange={handleInputChange}
            />
            {validationErrors.telephone && (
              <p className='text-red-500'>{validationErrors.telephone}</p>
            )}
          </div>
          <div className='my-5'>
            <label className='mt-10 font-bold'>Email *</label>
            <input
              type='email'
              name='email'
              disabled={true}
              className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
              value={formValues.email || ''}
              onChange={handleInputChange}
            />
            {validationErrors.email && <p className='text-red-500'>{validationErrors.email}</p>}
          </div>
        </section>

        <section className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg'>
          <div className='my-5'>
            <label className='mt-10 font-bold'>Choisir une ou plusieurs villes *</label>
            <p className='text-sm'>
              Les villes que vous aurez choisies seront les villes où vous pouvez faire des visites.
            </p>
            <div className='relative mt-3'>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE}
                className='rounded-full outline-none pl-3 w-full p-5 cursor-pointer mt-1 py-2 mb-1'
                onPlaceSelected={(place) => handleCityClick(place)}
                options={{
                  types: ['(regions)'],
                  componentRestrictions: { country: 'fr' },
                }}
              />
            </div>
            <div className='mt-5 flex gap-2'>
              {selectedCities.map((city, index) => (
                <p
                  key={index}
                  className='bg-white flex justify-between	 items-center cursor-pointer text-black w-fit font-bold py-2 px-4 rounded-full shadow border p-4 shadow'
                >
                  {city.nom} {city.codePostal}
                  <span
                    className='ml-2 p-1 border rounded-full text-red-500 hover:text-white hover:bg-red-500  cursor-pointer'
                    onClick={() => removeCity(index)}
                  >
                    <Close />
                  </span>
                </p>
              ))}
            </div>
          </div>
        </section>

        <div className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg'>
          <label className='form-label '>Parlez de vous</label>
          <textarea
            name='parlezDeVous'
            className='h-40 rounded-lg outline-none pl-3 w-full p-10px mt-1 py-2 mb-1'
            value={formValues.parlezDeVous || ''}
            onChange={handleInputChange}
          />
        </div>

        <button type='submit' className='bg-orange' onClick={handleSubmit}>
          Valider
        </button>
      </div>
    </div>
  )
}

export default withAuth(CreerProfilVisiteur)
