
import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
export const createPaymentIntent = async (token:any,amount:any,connectedAccountID:any,feeAmount:any, visiteId:any) => {
    try {
      const response = await Axios.post(`${SERVER_APP_DOMAIN}api/paiement/create-payment-intent`,
          { amount, connectedAccountID, feeAmount,visiteId  },  {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      },);
      const { clientSecret } = response.data;
     
      return {success:true,clientSecret};
    } catch (error:any) {

      return {success:false,message : error.response.data.error }
  
    }
  };
  
  export const verifyIdentity = async (id:any ) => {
 
    try {
      const response = await Axios.get(`${SERVER_APP_DOMAIN}api/paiement/verifyIdentity?id=${id}`);

      return response;
    } catch (error) {
      console.error('Erreur lors de la vérif', error);
    
    }
  };

  export const verifyInfosAccountStripe = async (id:any ) => {
 
    try {
      const response = await Axios.get(`${SERVER_APP_DOMAIN}api/paiement/verifyAccountInfos?id=${id}`);

      return response;
    } catch (error) {
      console.error('Erreur lors de la vérif', error);
    
    }
  };




export const getAuthLink = async (token:any,id:any,email:any) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/paiement/getLink`, { id ,email }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, 
    },);
    
    const url = response.data.lien
    return url;
  
 
} catch (error) {
  console.error('Erreur lors de la récupération du link', error);

}}




export const refundPayment = async (token:any,visiteId:any) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/paiement/refund`, { visiteId }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, 
    },);
    
 
    return response as any
  
 
} catch (error) {
  console.error('Erreur lors du remboursement', error);

}}





